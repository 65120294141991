import axios from 'axios';

export async function makeRestApiRequest(method, endpoint, data) {
	//https://newapi.tltid.com
	//335f5352088d7d9bf74191e006d8e24c1622616891
	let baseUrl =  localStorage.getItem('ndc_api_url');
	let auth_token = localStorage.getItem('ndc_auth_token');
	const options = {
		method,
		url: endpoint,
		baseURL: baseUrl,
		headers: {
            "Content-Type": "application/json",
            "Authorization" : auth_token
		},
    };
	if (['PATCH', 'POST', 'PUT'].includes(method)) {
		options.data = data;
	} else {
		options.params = data;
	}

    const response = await axios.request(options);
    if(response.data.data){
        return response.data.data;
    }else{
        return response.data;
    }
}