import axios from 'axios';
import store from '@/store';
import { airshop_request,airofferprice_req,airshop_return_request,airshop_multireturn_request} from "@/utils/request-mockup";
import { makeRestApiRequest } from "@/utils/helpers";

export default {
    namespaced: true,
    state: {
        bflightresultvisible : true,
        bflightlistvisible : false,
        dealOffers : [],
        jsonviewer : {
            airshop_request : {},
            airshop_response : {},
            airprice_request : {},
            airprice_response : {},
        },
        dealSelected : {}
    },
    getters : {
        getFlightResultVisible(state) {
            return state.bflightresultvisible;
        },
        getFlightListVisible(state) {
            return state.bflightlistvisible;
        },
        getDealOffer(state){
            return state.dealOffers;
        },
        getJsonViewser(state){
            return state.jsonviewer;
        },
        getDealSelected(state){
            return state.dealSelected;
        }
    },
    mutations : {
        setFlightResultVisible(state, payload) {
            state.bflightresultvisible = payload;
        },
        setFlightListVisible(state, payload) {
            state.bflightlistvisible = payload;
        },
        setDealOffer(state, payload) {
            state.dealOffers = payload;
        },
        setAirshopRequest(state, payload){
            state.jsonviewer.airshop_request = payload;
        },
        setAirshopResponse(state, payload){
            state.jsonviewer.airshop_response = payload;
        },
        setAirpriceRequest(state, payload){
            state.jsonviewer.airprice_request = payload;
        },
        setAirpriceResponse(state, payload){
            state.jsonviewer.airprice_response = payload;
        },
        SetDealSelected(state, payload){
            state.dealSelected = payload;
        }
    },
    actions : {
        init({ state, dispatch }) {},
         setFlightResultVisible({ commit}, payload) {
            commit('setFlightResultVisible', payload)
         },
         setFlightListVisible({ commit}, payload) {
            commit('setFlightListVisible', payload)
            //store.dispatch("common/setAppLoaderstatus",true);
        },
    async flightSearchForm({ commit}, payload){
        commit('setDealOffer', []); 
        airshop_multireturn_request["AirShoppingRQ"]["CoreQuery"]["OriginDestinations"]["OriginDestination"] = [];
        for(var pindex=0; pindex<payload.length; pindex++){
            var val = {}
            val['Departure'] ={}
            val['Departure']['AirportCode'] = payload[pindex].orgin_aircode;
            val['Arrival']={}
            val['Arrival']['AirportCode'] = payload[pindex].dest_aircode;
            val['Arrival']['Date']=payload[pindex].arrival_date;
            val["Arrival"]["ArrivalTime"]={};
            payload[pindex].fromTime!=" " &&  payload[pindex].fromTime!=undefined ? val["Arrival"]["ArrivalTime"]["From"]=payload[pindex].fromTime + "00" : val["Arrival"]["ArrivalTime"]["From"]=="";
            payload[pindex].toTime!=" " &&  payload[pindex].toTime!=undefined ? val["Arrival"]["ArrivalTime"]["To"]=payload[pindex].toTime + "00" : val["Arrival"]["ArrivalTime"]["To"]=="";
            //val["Arrival"]["ArrivalTime"]["TO"]=payload[pindex].toTime + "00";
            payload[0]. plus_oneday_option==false ? val["PlusOnedayOption"]="N" : val["PlusOnedayOption"]="Y";
            airshop_multireturn_request["AirShoppingRQ"]["CoreQuery"]["OriginDestinations"]["OriginDestination"].push(val)
           let search_req_param = payload[pindex].return_date !="" && payload[pindex].return_date!=undefined ? airshop_return_request :payload[pindex].trip_type=="oneway"? airshop_request : airshop_multireturn_request;
           //var passengercount=parseInt(payload[pindex].passengerAdult)+parseInt(payload[pindex].passengerChild)+parseInt(payload[pindex].passengerInfant);
           search_req_param["AirShoppingRQ"]["DataLists"]["PassengerList"]["Passenger"]=[];
           var k=1;
            //for(var k=1;k<passengercount+1;k++){
            for(var p= parseInt(payload[pindex].passengerAdult); p>0;p--){
                var passenger={};
                passenger["PassengerID"]="T"+k++;
                passenger["PTC"]="ADT";
                search_req_param["AirShoppingRQ"]["DataLists"]["PassengerList"]["Passenger"].push(passenger);
            }
            for(var l= parseInt(payload[pindex].passengerChild); l>0;l--){
                var passenger1={};
                passenger1["PassengerID"]="T"+k++;
                passenger1["PTC"]="CHD";
                search_req_param["AirShoppingRQ"]["DataLists"]["PassengerList"]["Passenger"].push(passenger1);
            }
            for(var m= parseInt(payload[pindex].passengerInfant); m>0;m--){
                var passenger2={};
                passenger2["PassengerID"]="T"+k++;
                passenger2["PTC"]="INF";
                search_req_param["AirShoppingRQ"]["DataLists"]["PassengerList"]["Passenger"].push(passenger2);
            }
            let cache_req = search_req_param["AirShoppingRQ"]["CoreQuery"]["OriginDestinations"]["OriginDestination"][0];
            let preference_req= search_req_param["AirShoppingRQ"]["Preference"];
            //console.log(payload[0].cabin_type,"jkhkjhkj")
            for(var i=0;i<payload[0].cabin_type.length;i++){
                if(payload[0].cabin_type=="ALL"){
                    preference_req["Cabin"]=payload[0].cabin_type;
                    preference_req["AdditionalCabinType"]="";
                }
                else{
                    if(payload[0].cabin_type.length > 1 && i==0) {
                        preference_req["Cabin"]=payload[0].cabin_type[0];
                        preference_req["AdditionalCabinType"]=payload[0].cabin_type[1];
                    }
                    else if(i!=0 && i!=payload[0].cabin_type.length-1){
                        preference_req["AdditionalCabinType"]+=","+payload[0].cabin_type[i+1];
                        preference_req["Cabin"]=payload[0].cabin_type[0];
                    }
                    else if(payload[0].cabin_type.length==1){
                        preference_req["Cabin"]=payload[0].cabin_type[0];
                        preference_req["AdditionalCabinType"]="";
                    }
                }
            }
            preference_req["NoOfStops"]=payload[0].no_of_stops;
            payload[0]. MaxmiumTime!=" " && payload[0]. MaxmiumTime!=undefined ? preference_req["ConnectionTime"]["Max"]=payload[0].MaxmiumTime*60 : preference_req["ConnectionTime"]["Max"]="";
            preference_req["ConnectionTime"]["Min"]="";
            preference_req["NoOfResults"]=payload[0].no_of_result;
            payload[0].need_best_itenary==false ? preference_req["NeedBestItinenery"]="N":preference_req["NeedBestItinenery"]="Y";
            payload[0].double_one_way==false ? preference_req["DoubleOneWay"]="N" : preference_req["DoubleOneWay"]="Y";
            payload[0].avoid_us_flag==false ? preference_req["CountryPreference"]["ExcludeCountry"]=[]:preference_req["CountryPreference"]["ExcludeCountry"][0]='US';
            payload[0].include_luggage==false ? preference_req["FreeBaggage"]="N" : preference_req["FreeBaggage"]="Y";
            payload[0].direct_flight==false ? preference_req["DirectFlight"]="N" : preference_req["DirectFlight"]="Y";
            payload[0].near_by_airport==false ? preference_req["NearByAirports"]="N" : preference_req["NearByAirports"]="Y";
            payload[0].plus_oneday_option==false ? cache_req["PlusOnedayOption"]="N" : cache_req["PlusOnedayOption"]="Y";
            cache_req["Departure"]["AirportCode"] =  payload[0].orgin_aircode;
            //cache_req["Departure"]["Date"] =  payload[0].depart_date;
            cache_req["Arrival"]["AirportCode"] =  payload[0].dest_aircode;
            cache_req["Arrival"]["Date"] =  payload[0].arrival_date;
            payload[0].fromTime!=" " &&  payload[0].fromTime!=undefined ? cache_req["Arrival"]["ArrivalTime"]["From"]=payload[0].fromTime + "00": cache_req["Arrival"]["ArrivalTime"]["From"]="";
            payload[0].toTime!=" " &&  payload[0].toTime!=undefined ? cache_req["Arrival"]["ArrivalTime"]["To"]=payload[0].toTime + "00" : cache_req["Arrival"]["ArrivalTime"]["To"]="";
           if(payload[pindex].return_date){ 
            let cache_return = search_req_param["AirShoppingRQ"]["CoreQuery"]["OriginDestinations"]["OriginDestination"][1];
            cache_return["Departure"]["AirportCode"] = payload[pindex].dest_aircode ;
            //cache_return["Departure"]["Date"] =  payload[pindex].depart_date;
            cache_return["Arrival"]["AirportCode"] = payload[pindex].orgin_aircode;
            cache_return["Arrival"]["Date"] =  payload[pindex].arrival_date;
            payload[pindex].fromTime!=" " &&  payload[pindex].fromTime!=undefined ? cache_return["Arrival"]["ArrivalTime"]["From"]=payload[0].fromTime+"00" : cache_return["Arrival"]["ArrivalTime"]["From"]="" ;
            payload[pindex].toTime!=" " &&  payload[pindex].toTime!=undefined ? cache_return["Arrival"]["ArrivalTime"]["To"]=payload[0].toTime+"00" : cache_return["Arrival"]["ArrivalTime"]["To"]="" ;
            if(payload[pindex].return_date){
                cache_return["Arrival"]["Date"] = payload[pindex].return_date;
               }
            }
            let master_req = JSON.parse(JSON.stringify(search_req_param));
            commit('setAirshopRequest', master_req);
            store.dispatch("common/setAppLoaderstatus",true);
            let res = await makeRestApiRequest('POST', `/AirShopping`, search_req_param);
            //let res = await makeRestApiRequest('GET', `http://localhost:8080/mocks/AirshoppingRS.json`, search_req_param);
            store.dispatch("common/setAppLoaderstatus",false);
            let master_date = JSON.parse(JSON.stringify(res));
            commit('setAirshopResponse', master_date);
            if (res.AirShoppingRS.Errors) {return res.AirShoppingRS.Errors;}
            
            let {
                FlightList,
                FlightSegmentList
            } = res.AirShoppingRS.DataLists;
             
            let shoppingResponseId = res.AirShoppingRS.ShoppingResponseId;

            res.AirShoppingRS.OffersGroup.AirlineOffers.map((airOffer, airOfferInx) => {
                
                airOffer.Offer.map(offer => {
                    var {
                        Service,
                        FareComponent,
                        Refundable,
                        FareDetail,
                        PassengerQuantity,
                    } = offer.OfferItem.find(s => s.PassengerType == 'ADT');
                    offer['flightOfferId'] = offer.OfferID;
                    offer["shoppingResponseId"] = shoppingResponseId;
                    offer['TotalPrice']['ConvertedCurrencyPrice'] = offer.TotalPrice.BookingCurrencyPrice;
                    offer.Flights = Service.map((flight, k) => {
                        let disFlights = FlightList.Flight.find(s => s.FlightKey == flight.FlightRefs);
                        disFlights.Segments = disFlights.SegmentReferences.trim().split(' ').map(seg => {
                            let dspSeg = FlightSegmentList.FlightSegment.find(s => s.SegmentKey == seg);
                            return dspSeg;
                        });
                        return disFlights;
                    })
                });

            });
            
            let dealOffers = res.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer;
            commit('setDealOffer', dealOffers); 
            store.dispatch("flights/setFlightListVisible", true);
        }  
        },   
        async getAirOfferPrice({ commit}, payload){
            let airoffer_req_param = airofferprice_req;
            let cache_req = airoffer_req_param["OfferPriceRQ"]["Query"]["Offer"][0];
            cache_req["OfferID"] =  payload.offerId;
            airoffer_req_param["OfferPriceRQ"]["ShoppingResponseId"] = payload.shoppingResponseId;
            commit('setAirpriceRequest', airoffer_req_param);
            store.dispatch("common/setAppLoaderstatus",true);
            let res = await makeRestApiRequest('POST', `/AirOfferPrice`, airoffer_req_param);
            //let res = await makeRestApiRequest('GET', `http://localhost:8080/mocks/airoffer.json`, airoffer_req_param);
            store.dispatch("common/setAppLoaderstatus",false);

            let master_date = JSON.parse(JSON.stringify(res));
            commit('setAirpriceResponse', master_date);
            console.log(master_date,"airprice");
            if (res.OfferPriceRS.Errors) {return res.OfferPriceRS.Errors}

            let {
                FlightList,
                FlightSegmentList
            } = res.OfferPriceRS.DataLists;

            let shoppingResponseId = res.OfferPriceRS.ShoppingResponseId;
            let offerResponseId = res.OfferPriceRS.OfferResponseId;
            res.OfferPriceRS.PricedOffer.map((offer, airOfferInx) => {
                //airOffer.Offer.map(offer => {
                    var {
                        Service
                    } = offer.OfferItem.find(s => s.PassengerType == 'ADT');

                    offer['flightOfferId'] = offer.OfferID;
                    offer["shoppingResponseId"] = shoppingResponseId;
                    offer["offerResponseId"] = offerResponseId;
                    offer['TotalPrice']['ConvertedCurrencyPrice'] = offer.TotalPrice.BookingCurrencyPrice;

                    offer.Flights = Service.map((flight, k) => {
                      
                        let disFlights = FlightList.Flight.find(s => s.FlightKey == flight.FlightRefs);
                        
                        disFlights.Segments = disFlights.SegmentReferences.trim().split(' ').map(seg => {
                            let dspSeg = FlightSegmentList.FlightSegment.find(s => s.SegmentKey == seg);
                            return dspSeg;
                        });
                        //let disFlights = FlightList[flight.FlightRefs];
                        //console.log(disFlights);
                        return disFlights;
                    });
                //})
            });
            let dealOffers = res.OfferPriceRS.PricedOffer;
            return dealOffers;
        }
        
    }
}