import axios from 'axios';
import store from '@/store';
import { createorder_req , retreivebooking_req , createticket_req } from "@/utils/request-mockup";
import { makeRestApiRequest } from "@/utils/helpers";

export default {
    namespaced: true,
    state: {
        bapploaderstatus : false,
        jsonviewer : {
            createorder_request : {},
            createorder_response : {},
            vieworder_request : {},
            vieworder_response : {},
            createticket_request : {},
            createticket_response : {},
        },
        booking_list : []
    },
    getters : {
        getAppLoaderstatus(state) {
            return state.bapploaderstatus;
        },
        getJsonViewser(state){
            return state.jsonviewer;
        },
        getBookingList(state){
            return state.booking_list;
        }
    },
    mutations : {
        setAppLoaderstatus(state, payload) {
            state.bapploaderstatus = payload;
        },
        setCreateorderRequest(state, payload){
            state.jsonviewer.createorder_request = payload;
        },
        setCreateorderResponse(state, payload){
            state.jsonviewer.createorder_response = payload;
        },
        setVieworderRequest(state, payload){
            state.jsonviewer.vieworder_request = payload;
        },
        setVieworderResponse(state, payload){
            state.jsonviewer.vieworder_response = payload;
        },
        setViewticketRequest(state, payload){
            state.jsonviewer.createticket_request = payload;
        },
        setViewticketResponse(state, payload){
            state.jsonviewer.createticket_response = payload;
        },
        addBookingBulk(state, payload){
            state.booking_list = payload;
        },
        addBookingList(state, payload){
            state.booking_list.push(payload);
        }
    },
    actions : {
        init({ commit }) {
            let bookingjson = (localStorage.getItem('ndc_bookings')) ? (localStorage.getItem('ndc_bookings')) : "[]";
            let bookingres = JSON.parse(bookingjson);
            commit('addBookingBulk', bookingres);
        },
         setAppLoaderstatus({ commit}, payload) {
            commit('setAppLoaderstatus', payload)
         },
         async createOrder({ commit}, payload) {
            let createorder_req_param = createorder_req;
            let dealoffer = payload.dealoffer;
            let passgenerdetails=payload.passengerdetails;
            createorder_req_param["OrderCreateRQ"]["ShoppingResponseId"] = dealoffer.shoppingResponseId;
            createorder_req_param["OrderCreateRQ"]["OfferResponseId"] = dealoffer.offerResponseId;
            createorder_req_param["OrderCreateRQ"]["Query"]["Order"]["Offer"][0]["OfferID"] = dealoffer.offerId;
            //createorder_req_param["OrderCreateRQ"]["Payments"]["Payment"][0]["Method"]["Amount"] = dealoffer.disPrice;
            createorder_req_param["OrderCreateRQ"]["Payments"]["Payment"][0]["Amount"] = dealoffer.totalamount;
            createorder_req_param["OrderCreateRQ"]["DataLists"]["PassengerList"]["Passenger"][0]["NameTitle"]=passgenerdetails.title
            createorder_req_param["OrderCreateRQ"]["DataLists"]["PassengerList"]["Passenger"][0]["Gender"]=passgenerdetails.gender
            createorder_req_param["OrderCreateRQ"]["DataLists"]["PassengerList"]["Passenger"][0]["FirstName"]=passgenerdetails.first
            createorder_req_param["OrderCreateRQ"]["DataLists"]["PassengerList"]["Passenger"][0]["LastName"]=passgenerdetails.last
            createorder_req_param["OrderCreateRQ"]["DataLists"]["PassengerList"]["Passenger"][0]["TravelDocument"][0]["IssuingCountry"]=passgenerdetails.paxcountry
            createorder_req_param["OrderCreateRQ"]["DataLists"]["ContactList"]["ContactInformation"][0]["PassengerContact"]["EmailAddress"]=passgenerdetails.email
            createorder_req_param["OrderCreateRQ"]["DataLists"]["ContactList"]["ContactInformation"][0]["PassengerContact"]["Phone"]["PhoneNumber"]=passgenerdetails.phone
            createorder_req_param["OrderCreateRQ"]["DataLists"]["ContactList"]["ContactInformation"][0]["Address"]["Street"]=passgenerdetails.address
            createorder_req_param["OrderCreateRQ"]["DataLists"]["ContactList"]["ContactInformation"][0]["Address"]["CityName"]=passgenerdetails.city
            createorder_req_param["OrderCreateRQ"]["DataLists"]["ContactList"]["ContactInformation"][0]["Address"]["StateProv"]=passgenerdetails.state
            createorder_req_param["OrderCreateRQ"]["DataLists"]["ContactList"]["ContactInformation"][0]["Address"]["PostalCode"]=passgenerdetails.zip
            createorder_req_param["OrderCreateRQ"]["DataLists"]["ContactList"]["ContactInformation"][0]["Address"]["CountryCode"]=passgenerdetails.adscountry
            createorder_req_param["OrderCreateRQ"]["Payments"]["Payment"][0]["Method"]["PaymentCard"]["CardHolderName"]=passgenerdetails.creditcard
            createorder_req_param["OrderCreateRQ"]["Payments"]["Payment"][0]["Method"]["PaymentCard"]["CardNumber"]=passgenerdetails.creditnumber
            createorder_req_param["OrderCreateRQ"]["Payments"]["Payment"][0]["Method"]["PaymentCard"]["EffectiveExpireDate"]["Expiration"]=passgenerdetails.creditexpire
            createorder_req_param["OrderCreateRQ"]["Payments"]["Payment"][0]["Method"]["PaymentCard"]["SeriesCode"]=passgenerdetails.creditcvv
            //let paxdetails = payload.dealoffer;
            commit('setCreateorderRequest', createorder_req_param);
            store.dispatch("common/setAppLoaderstatus",true);
            let res = await makeRestApiRequest('POST', `/AirOrderCreate`, createorder_req_param);
            //let res = await makeRestApiRequest('GET', `http://localhost:8080/mocks/createorder.json`, createorder_req_param);
            store.dispatch("common/setAppLoaderstatus",false);

            let master_date = JSON.parse(JSON.stringify(res));
            commit('setCreateorderResponse', master_date);

            return res;
         },
         addBookingList({ commit}, payload){
            commit('addBookingList', payload)

            let bookingjson = (localStorage.getItem('ndc_bookings')) ? (localStorage.getItem('ndc_bookings')) : "[]";
            let bookingres = JSON.parse(bookingjson);
            bookingres.push(payload);
            localStorage.setItem('ndc_bookings', JSON.stringify(bookingres));
         },
         async retreiveBooking({ commit}, payload){
            let booking_req = retreivebooking_req;
            booking_req["OrderRetreiveRQ"]["Query"]["OrderID"] = payload.bookingid;
            booking_req["OrderRetreiveRQ"]["Query"]["GdsBookingReference"] = payload.pnr;

            commit('setVieworderRequest', booking_req);

            store.dispatch("common/setAppLoaderstatus",true);
            let res = await makeRestApiRequest('POST', `/AirOrderRetreive`, booking_req);
            //let res = await makeRestApiRequest('GET', `http://localhost:8080/mocks/vieworder.json`, booking_req);
            store.dispatch("common/setAppLoaderstatus",false);
            
            //commit('setVieworderResponse', res);
            let master_date = JSON.parse(JSON.stringify(res));
            commit('setVieworderResponse', master_date);
            return res;
         },
         async createTicket({ commit}, payload){
            let booking_req = createticket_req;
            booking_req["AirDocIssueRQ"]["Query"]["OrderID"] = payload.bookingid;
            booking_req["AirDocIssueRQ"]["Query"]["GdsBookingReference"] = payload.pnr;

            commit('setViewticketRequest', booking_req);

            store.dispatch("common/setAppLoaderstatus",true);
            let res = await makeRestApiRequest('POST', `/AirDocIssue`, booking_req);
            //let res = await makeRestApiRequest('GET', `http://localhost:8080/mocks/createticket.json`, booking_req);
            store.dispatch("common/setAppLoaderstatus",false);
            
            //commit('setVieworderResponse', res);
            let master_date = JSON.parse(JSON.stringify(res));
            commit('setViewticketResponse', master_date);
            return res;
         }
    }
}